import { useEffect } from "react";
import Newsletter from "../components/Newsletter";

export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* <!--================ Start Features Area =================--> */}
            <section className="features_area section_gap_top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="main_title">
                                <h2>service offers </h2>
                                <p>
                                    Is give may shall likeness made yielding
                                    spirit a itself togeth created after sea{" "}
                                    <br /> is in beast beginning signs open god
                                    you're gathering ithe
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_inner">
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="img/services/s1.png" alt="" />
                                <h4>Wp developing</h4>
                                <p>
                                    Creeping for female light years that lesser
                                    can't evening heaven isn't bearing tree
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="img/services/s2.png" alt="" />
                                <h4>UI/ux design</h4>
                                <p>
                                    Creeping for female light years that lesser
                                    can't evening heaven isn't bearing tree
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="img/services/s3.png" alt="" />
                                <h4>Web design</h4>
                                <p>
                                    Creeping for female light years that lesser
                                    can't evening heaven isn't bearing tree
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature_item">
                                <img src="img/services/s4.png" alt="" />
                                <h4>seo optimize</h4>
                                <p>
                                    Creeping for female light years that lesser
                                    can't evening heaven isn't bearing tree
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================ End Features Area =================--> */}

            {/* <!--================ Start Testimonial Area =================--> */}
            <div className="testimonial_area section_gap_bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="main_title">
                                <h2>client say about me</h2>
                                <p>
                                    Is give may shall likeness made yielding
                                    spirit a itself togeth created after sea is
                                    in beast <br />
                                    beginning signs open god you're gathering
                                    ithe
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="testi_slider owl-carousel">
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Elite Martin</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t2.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Davil Saden</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Elite Martin</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t2.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Davil Saden</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Elite Martin</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testi_item">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img
                                            src="img/testimonials/t2.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="testi_text">
                                            <h4>Davil Saden</h4>
                                            <p>
                                                Him, made can't called over
                                                won't there on divide there male
                                                fish beast own his day third
                                                seed sixth seas unto. Saw from{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--================ End Testimonial Area =================--> */}
            <Newsletter />
        </>
    );
}
