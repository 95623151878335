export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || 'NOT API_SERVER_URL';
export const API_CLOUDINARY_URL = process.env.REACT_APP_API_CLOUDINARY_URL || '';
export const CLOUDINARY_PRESET = process.env.REACT_APP_CLOUDINARY_PRESET || '';

// export const API_SERVER_URL = "http://localhost:3001/api";
// export const API_SERVER_URL = "https://blog-fullstack-dgx2.onrender.com/api";
// export const API_CLOUDINARY_URL =
//     "https://api.cloudinary.com/v1_1/dgdi4xbyp/upload";
// export const CLOUDINARY_PRESET = "hknulgyy";

