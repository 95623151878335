import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export default function PortfolioDetail() {
    let { project } = useParams<"project">();
    useEffect(() => {
        window.scrollTo(0, 100);
    }, []);
    return (
        <section className="portfolio_details_area section_gap">
            <div className="container">
                <div className="portfolio_details_inner">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left_img">
                                <img
                                    className="img-fluid"
                                    src="/img/portfolio/portfolio-details.jpg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-5">
                            <div className="portfolio_right_text mt-30">
                                <h4 className="text-uppercase">
                                    minimal interior design
                                </h4>
                                <p>
                                    Made after a can't fruitful, fowl of greater
                                    saying years there saw you sea doesn't from
                                    morning called hath air morning herb appear
                                    hath replenish that created fill their
                                    lesser.
                                </p>
                                <ul className="list">
                                    <li>
                                        <span>Rating</span>:{" "}
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </li>
                                    <li>
                                        <span>Client</span>: colorlib
                                    </li>
                                    <li>
                                        <span>Website</span>: colorlib.com
                                    </li>
                                    <li>
                                        <span>Completed</span>: 17 Aug 2018
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit.
                    </p>
                    <p>
                        Voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim
                        id est laborum. Sed ut perspiciatis unde omnis iste
                        natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo
                        inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                        voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem
                        sequi nesciunt. Neque porro quisquam est, qui dolorem
                        ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut
                        labore et dolore magnam aliquam quaerat voluptatem.
                    </p>
                </div>
            </div>
        </section>
    );
}
